import Vue from 'vue'

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import TabMenu from 'primevue/tabmenu';
import FullCalendar from 'primevue/fullcalendar';
import FileUpload from 'primevue/fileupload';
import Card from 'primevue/card';
import Textarea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import UploadCloudinary from './components/UploadCloudinary.vue';

Vue.component('InputText', InputText);
Vue.component('Button', Button);
Vue.component('Toast', Toast);
Vue.component('TabMenu', TabMenu);
Vue.component('FullCalendar', FullCalendar);
Vue.component('FileUpload', FileUpload);
Vue.component('Card', Card);
Vue.component('Textarea', Textarea);
Vue.component('Dialog', Dialog);
Vue.component('Calendar', Calendar);
Vue.component('UploadCloudinary', UploadCloudinary);