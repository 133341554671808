import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCH_CWMpwpoy_HzRd71FBki1YIVcVzG4ow",
    authDomain: "paladruproject.firebaseapp.com",
    databaseURL: "https://paladruproject-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "paladruproject",
    storageBucket: "paladruproject.appspot.com",
    messagingSenderId: "152444444111",
    appId: "1:152444444111:web:93e88765b974f373b106ba",
    measurementId: "G-T00JSDPEBE"
  };
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
const usersCollection = db.collection('users');
const messagesCollection = db.collection('messages');
const documentsCollection = db.collection('documents');
const agendasCollection = db.collection('agendas');

// export utils/refs
export {
    db,
    auth,
    usersCollection,
    messagesCollection,
    documentsCollection,
    agendasCollection
  }
