<template>
  <div >
    <br />
    <img
      style="max-width: 30em; border-radius: 50%;width:90%"
      alt="paladru"
      src="https://res.cloudinary.com/dslknammm/image/upload/v1626426016/paladru/68669575_10218769552370532_2085158016063111168_n_nqvbkk_xzwun6.jpg"
    />
    <h3>Bienvenue</h3>
    <br />
    <h3>Infos utiles</h3>
    <div>Code cadenas haut : 2326</div>
    <div>Code cadenas barque : 1326</div>
    <div>Code cadenas gros tabur : 2326</div>
  </div>
</template>

<script>
export default {};
</script>
