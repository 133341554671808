<template>
  <div id="app">
    <img
      style="width: 100%"
      alt="ponton lac"
      src="https://res.cloudinary.com/dslknammm/image/upload/v1626425740/paladru/paladrubandeau_lgepwc_cwdold.jpg"
    />

    <TabMenu v-if="showNav" :model="items" />
    <router-view />
    <br />
    <Toast />

    <Dialog
      :visible.sync="showDecoConfirm"
      :contentStyle="{ overflow: 'visible' }"
    >
      <template #header>
        <h2>Etes-vous sur de vouloir vous déconnecter ?</h2>
      </template>
      <div></div>
      <template #footer>
        <Button label="Oui" autofocus @click="accept()" />
        <Button label="Non" @click="reject()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { store } from "./store";

export default {
  computed: {
    showNav() {
      return Object.keys(store.state.userProfile).length > 1;
    },
  },
  methods: {
    confirmdeco() {
      this.showDecoConfirm = true;
      console.log(this.showDecoConfirm);
    },
    accept() {
      store.dispatch("logout");
      this.showDecoConfirm = false;
    },
    reject() {
      this.showDecoConfirm = false;
    },
  },
  data() {
    return {
      showDecoConfirm: false,
      items: [
        { label: "Accueil", icon: "pi pi-fw pi-home", to: "/" },
        {
          label: "Calendrier",
          icon: "pi pi-fw pi-calendar",
          to: "/calendar",
        },
        {
          label: "Documentation",
          icon: "pi pi-fw pi-file",
          to: "/documentation",
        },
        {
          label: "Mur d'échange",
          icon: "pi pi-fw pi-comments",
          to: "/messages",
        },
        {
          label: "Contacts",
          icon: "pi pi-fw pi-user",
          to: "/contacts",
        },
        {
          label: "Se déconnecter",
          icon: "pi pi-fw pi-sign-out",
          command: () => {
            this.confirmdeco();
          },
        },
      ],
    };
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  max-width: 1300px;
  height: 100%;
  margin: auto;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
body {
  background-image: url("https://res.cloudinary.com/dslknammm/image/upload/v1626425808/paladru/paladru_ilbhqf_jgsq22.jpg");
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: 100%;
  background-position: 25% 25%;
  background-color: #eafaff;
  margin: 0;
}
.p-dialog-header {
  height: 60px;
}

.p-dialog-header {
  height: 60px;
}

.p-dialog
{
  max-width: 500px;
  width: 100%;
}
</style>
