<template>
  <Button icon="pi pi-upload" label="Parcourir" @click="uploadImage()" />
</template>

<script>
export default {
  name: "UploadCloudinary",
  props: {
    folder: String,
    iscamera: { type: Boolean, default: false },
    iscrop: { type: Boolean, default: false },
  },
  methods: {
    uploadImage() {
      var sources = ["local", "url", "camera"];
      if (!this.iscamera) sources = ["local", "url"];
      var formats = ["png", "gif", "jpeg", "jpg"];
      if (!this.iscamera) formats = ["*"];
      window.cloudinary
        .createUploadWidget(
          {
            cloudName: "dslknammm",
            sources: sources,
            multiple: false,
            language: "fr",
            folder: `paladru/${this.folder}`,
            text: {
              fr: {
                or: "ou",
                back: "Retour",
                advanced: "Avancés",
                close: "Fermer",
                no_results: "No results",
                search_placeholder: "Search files",
                about_uw: "About the Upload Widget",
                menu: {
                  files: "Mes fichiers",
                  web: "Adresse web",
                  camera: "Camera",
                },
                selection_counter: {
                  selected: "Sélectionner",
                },
                actions: {
                  upload: "Télécharger",
                  clear_all: "Effacer tout",
                  log_out: "Log out",
                },
                notifications: {
                  general_error: "Une erreur est survenue.",
                  general_prompt: "Êtes-vous sûr?",
                  limit_reached:
                    "Aucun autre fichier ne peut être sélectionné.",
                  invalid_add_url: "L'URL doit être valide.",
                  invalid_public_id: "Public ID cannot contain \\,?,&,#,%,<,>.",
                  no_new_files: "Les fichiers ont déjà été téléchargés.",
                  image_purchased: "Image Purchased",
                  video_purchased: "Video Purchased",
                  purchase_failed: "Purchase failed. Please try again.",
                  service_logged_out: "Service logged out due to error",
                  great: "Great",
                },
                advanced_options: {
                  public_id_ph: "ID Publique",
                  tags_ph: "Ajouter un tag",
                  add_new: "Ajouter un nouveau tag:",
                  upload_preset_placeholder: "Upload Preset",
                },
                landscape_overlay: {
                  title: "Landscape mode isn't supported",
                  description:
                    "Rotate your device back to portrait mode to continue.",
                },
                queue: {
                  title: "Tâches en cours",
                  title_uploading_with_counter:
                    "Téléchargement de {{num}} fichiers",
                  title_uploading: "Téléchargement des fichiers",
                  mini_title: "Téléchargé",
                  mini_title_uploading: "Téléchargement",
                  show_completed: "Show completed",
                  retry_failed: "Retry failed",
                  abort_all: "Annuler",
                  upload_more: "Upload more",
                  done: "Valider",
                  mini_upload_count: "{{num}} téléchargé(s)",
                  mini_failed: "{{num}} erreur(s)",
                  statuses: {
                    uploading: "Téléchargement...",
                    error: "Erreur",
                    uploaded: "Valider",
                    aborted: "Avortée",
                  },
                },
                uploader: {
                  filesize: {
                    na: "N/A",
                    b: "{{size}} Octet",
                    k: "{{size}} Ko",
                    m: "{{size}} Mo",
                    g: "{{size}} Go",
                    t: "{{size}} To",
                  },
                  errors: {
                    file_too_large:
                      "Taille du fichier ({{size}}) dépasse le maximum autorisé ({{allowed}})",
                    max_dimensions_validation:
                      "Image dimensions ({{width}}X{{height}}) are bigger than the maximum allowed: ({{maxWidth}}X{{maxHeight}})",
                    min_dimensions_validation:
                      "Image dimensions ({{width}}X{{height}}) are smaller than the minimum required: ({{minWidth}}X{{minHeight}})",
                    unavailable: "NA",
                    max_number_of_files: "Nombre maximum de fichiers dépassé",
                    allowed_formats: "Format de fichier non valide",
                    max_file_size: "Fichier trop grand",
                    min_file_size: "Fichier trop petit",
                  },
                },
                camera: {
                  main_title: "Caméra",
                  capture: "Capture",
                  cancel: "Annuler",
                  take_pic: "Prenez une photo et téléchargez-la",
                  explanation:
                    "Assurez-vous que votre caméra est connectée et que votre navigateur autorise la capture de la caméra. Lorsque vous êtes prêt, cliquez sur Recommencer.",
                  camera_error: "Impossible d'accéder à la caméra",
                  retry: "Recommencer",
                  file_name: "Camera_{{time}}",
                },
                local: {
                  browse: "Parcourir",
                  main_title: "Upload Files",
                  dd_title_single: "Déposez votre fichier ici",
                  dd_title_multi: "Drag and Drop assets here",
                  drop_title_single: "Drop a file to upload",
                  drop_title_multiple: "Drop files to upload",
                },
                url: {
                  main_title: "Remote URL",
                  inner_title: "URL publique du fichier à télécharger :",
                  input_placeholder:
                    "http://remote.site.example/images/remote-image.jpg",
                },
              },
            },
            show_powered_by: false,
            thumbnail_transformation: "w_200",
            uploadPreset: "paladru",
            clientAllowedFormats: formats,
            showSkipCropButton: false,
            croppingAspectRatio: 1,
            cropping: this.iscrop,
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log(result.info.secure_url);
              this.$emit("result", result.info);
              this.$toast.add({
                severity: "success",
                summary: "Média bien reçu",
                detail: "Votre média à bien été enregistré !",
                life: 3000,
              });
            }
            if (error) {
              console.log(result);
              this.$emit("result", "");
              this.$toast.add({
                severity: "error",
                summary: "Une Erreur s'est produite.",
                detail:
                  "Une erreur s'est produite lors du téléchargement de votre média.",
                life: 3000,
              });
            }
          }
        )
        .open();
    },
  },
};
</script>

<style scoped>
</style>
