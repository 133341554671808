import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'




Vue.config.productionTip = false;



import ToastService from 'primevue/toastservice';
Vue.use(ToastService);




let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
})

import PrimeVue from 'primevue/config';
import VueRouter from 'vue-router';

import '@/GlobalComponents'

import 'primevue/resources/themes/saga-blue/theme.css';     //theme
import 'primevue/resources/primevue.min.css';               //core css
import 'primeicons/primeicons.css';                       //icons
import 'primeflex/primeflex.css';

// all Cloudinary components:
import Cloudinary from "cloudinary-vue";

Vue.use(Cloudinary, {
  configuration: { cloudName: "dslknammm" }
});
Vue.use(PrimeVue);
Vue.use(router);
Vue.use(VueRouter);