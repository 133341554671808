import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { documentsCollection } from '../firebase'
import { messagesCollection } from '../firebase'
import { usersCollection } from '../firebase'
import { agendasCollection } from '../firebase'
import * as fb from '../firebase'
import router from '../router/index'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        documents: [],
        messages: [],
        users: [],
        agendas: [],
        userProfile: {}
    },
    mutations: {
        ...vuexfireMutations,
        setUserProfile(state, val) {
            state.userProfile = val;
        },
    },
    actions: {
        bindDocuments: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef('documents', documentsCollection)
        }),
        bindMessages: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef('messages', messagesCollection)
        }),
        bindUsers: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef('users', usersCollection)
        }),
        bindAgendas: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef('agendas', agendasCollection)
        }),
        addDocument: firestoreAction((context, payload) => {
            return documentsCollection.add(payload)
        }),
        addMessage: firestoreAction((context, payload) => {
            return messagesCollection.add(payload)
        }),
        addAgenda: firestoreAction((context, payload) => {
            return agendasCollection.add(payload)
        }),
        deleteDocument: firestoreAction((context, payload) => {
            documentsCollection
                .doc(payload)
                .delete()
        }),
        deleteAgenda: firestoreAction((context, payload) => {
            agendasCollection
                .doc(payload)
                .delete()
        }),
        updateDocument: firestoreAction((context, payload) => {
            documentsCollection
                .doc(payload.id)
                .set(payload)
        }),
        updateAgenda: firestoreAction((context, payload) => {
            agendasCollection
                .doc(payload.id)
                .set(payload)
        }),
        updateUser: firestoreAction((context, payload) => {
            usersCollection
                .doc(payload.id)
                .set(payload)
        }),
        async login({ dispatch }, form) {
            // sign user in
            const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)

            // fetch user profile and set in state
            dispatch('fetchUserProfile', user)
        },
        async fetchUserProfile({ commit }, user) {
            // fetch user profile
            const userProfile = await fb.usersCollection.doc(user.uid).get()

            var profile = userProfile.data();
            if (profile) {
                profile.id = user.uid;
                // set user profile in state
                commit('setUserProfile', profile)

                // change route to dashboard
                if (router.currentRoute.path === '/login') {
                    router.push('/')
                }
            } else
                router.push('/login')
        },
        async signup({ dispatch }, form) {
            // sign user up
            const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

            // create user profile object in userCollections
            await fb.usersCollection.doc(user.uid).set({
                prenom: form.prenom,
                nom: form.nom,
                email: form.email,
                mobile: form.mobile,
                photo: form.photo,
                color: form.color,
            })

            // fetch user profile and set in state
            dispatch('fetchUserProfile', user)
        },
        async logout({ commit }) {
            await fb.auth.signOut()

            // clear userProfile and redirect to /login
            commit('setUserProfile', {})
            router.push('/login')
        }
    },
})

export default store